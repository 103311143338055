import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { SEO, SocialMedia } from 'components';
import { useWindowSize } from 'hooks';
import axios from 'axios';
import { MailIcon, PhoneIcon, LocationIcon } from 'images/contact_details/';
import { LinkStyle } from 'components/Shared/Link';

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [howDidYouFindUs, setHowDidYouFindUs] = useState('');
  const [enquiryType, setEnquiryType] = useState('Quote');
  const [subscribe, setSubscribe] = useState(false);
  const [message, setMessage] = useState('');
  const [botField, setBotfield] = useState('');
  const [isMessageSending, setIsMessageSending] = useState(false);
  const [isMessageSent, setIsMessageSent] = useState(false);
  const { windowWidth } = useWindowSize();

  const encodeData = (data) => {
    const formData = new FormData();

    Object.keys(data).forEach((key) => formData.append(key, data[key]));

    return formData;
  };

  const addToMailingList = async (nameAndEmailAddress) => {
    const data = await axios({
      method: 'POST',
      url: '/.netlify/functions/add-to-mailing-list',
      data: {
        ...nameAndEmailAddress
      }
    })
      .then((response) => response.data)
      .catch((error) => error.message);

    return data;
  };

  const sendFormToNetlify = async (e) => {
    setIsMessageSending(true);

    const form = e.target;

    const options = {
      method: 'POST',
      body: encodeData({
        'form-name': form.getAttribute('name'),
        name,
        email,
        company,
        howDidYouFindUs,
        enquiryType,
        message
      })
    };

    return fetch('/', options)
      .then((response) => response)
      .catch((error) => console.error(error));
  };

  const resetForm = () => {
    setName('');
    setEmail('');
    setCompany('');
    setHowDidYouFindUs('');
    setEnquiryType('Quote');
    setSubscribe(false);
    setMessage('');
    setBotfield('');
    setIsMessageSending(false);
    setIsMessageSent(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (subscribe) {
      Promise.all([addToMailingList({ name, email }), sendFormToNetlify(e)]).then(() =>
        resetForm()
      );
    } else {
      sendFormToNetlify(e).then(() =>
        setTimeout(() => {
          resetForm();
        }, 1750)
      );
    }
  };

  const checkIfMessageAlreadySent = () => {
    if (isMessageSent) {
      setIsMessageSent(false);
    }
  };

  return (
    <Wrapper>
      <SEO
        pageSpecificTitle="Contact Us"
        pageSpecificDescription="Want to take things to the next level? Then let's talk."
      />
      <div>
        {windowWidth >= 1200 && (
          <div>
          <ContactDetails>
            <h1>
              Campaigns.
              <br />
              Evolved.
            </h1>
            <p>
              <MailIcon />
              <a style={{ textDecoration: 'underline' }} href="mailto:hello@agencyx.ie">
                hello@agencyx.ie
              </a>
            </p>
            <p>
              <PhoneIcon />
              <a href="tel:+35316706122">01 6706122</a>
            </p>
            <p>
              <LocationIcon />
              <span>
                12 Hatch Street Lower,
                <br />
                Dublin, D02 R682, Ireland
              </span>
            </p>
            <SocialMedia />
          </ContactDetails>
          <small>
              © 2023 Agency X. All rights reserved.
              <br />
              AgencyX Limited, registered in Ireland, Reg. No. 472825
              <br />
              12 Hatch Street Lower, Dublin, D02 R682, Ireland
            </small>
          </div>
        )}
        <Form
          id="Contact Us"
          name="Contact Us"
          method="POST"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit}>
          <Input type="hidden" name="form-name" value="Level 1 Submission" />
          <div hidden>
            <Label>
              Don’t fill this out:
              <Input
                name="bot-field"
                value={botField}
                onChange={(e) => {
                  checkIfMessageAlreadySent();
                  setBotfield(e.target.value);
                }}
              />
            </Label>
          </div>
          <p>Tell us about you</p>
          <Label htmlFor="name">Name</Label>
          <Input
            type="name"
            name="name"
            id="name"
            placeholder="name*"
            value={name}
            onChange={(e) => {
              checkIfMessageAlreadySent();
              setName(e.target.value);
            }}
            required
          />
          <Label htmlFor="email">Email</Label>
          <Input
            type="email"
            name="email"
            id="email"
            placeholder="email*"
            value={email}
            onChange={(e) => {
              checkIfMessageAlreadySent();
              setEmail(e.target.value);
            }}
            required
          />
          <Label htmlFor="company">Company</Label>
          <Input
            type="text"
            name="company"
            id="company"
            placeholder="company"
            value={company}
            onChange={(e) => {
              checkIfMessageAlreadySent();
              setCompany(e.target.value);
            }}
          />
          <p>How did you find us?*</p>
          <Label htmlFor="howDidYouFindUs">How did you find us?</Label>
          <Input
            type="text"
            name="howDidYouFindUs"
            id="howDidYouFindUs"
            placeholder="referral? google? etc."
            value={howDidYouFindUs}
            onChange={(e) => {
              checkIfMessageAlreadySent();
              setHowDidYouFindUs(e.target.value);
            }}
            required
          />
          <Label htmlFor="enquiryType">Enquiry type</Label>
          <Input
            aria-label="What is your enquiry type?"
            name="enquiryType"
            id="enquiryType"
            onChange={() => null}
            type="hidden"
            value={enquiryType}
          />
          <TextAndChoiceButtons>
            <p>What is your enquiry type?*</p>
            <ChoiceButtons>
              <ChoiceButton
                type="button"
                onClick={() => setEnquiryType('Quote')}
                selected={enquiryType === 'Quote'}>
                Quote
              </ChoiceButton>
              <ChoiceButton
                type="button"
                onClick={() => setEnquiryType('General')}
                selected={enquiryType === 'General'}>
                General
              </ChoiceButton>
            </ChoiceButtons>
          </TextAndChoiceButtons>
          <p>What is your enquiry?*</p>
          <Label htmlFor="message">Message</Label>
          <TextArea
            as="textarea"
            type="textarea"
            name="message"
            id="message"
            placeholder="enter message"
            cols="30"
            rows="10"
            value={message}
            onChange={(e) => {
              checkIfMessageAlreadySent();
              setMessage(e.target.value);
            }}
            required
          />
          <AddToMailingList htmlFor="addToMailingList">
            <span>Would you like to be added to our mailing list?</span>
            <HiddenCheckbox
              name="addToMailingList"
              id="addToMailingList"
              onChange={(e) => {
                checkIfMessageAlreadySent();
                setSubscribe(e.target.checked);
              }}
              type="checkbox"
              checked={subscribe}
              value={subscribe}
            />
            <CustomCheckbox />
          </AddToMailingList>
          <SubmitWrapper isMessageSent={isMessageSent}>
            <span>We&apos;ll be in touch shortly!</span>
            <Submit isMessageSending={isMessageSending} type="submit">
              {isMessageSending ? 'Sending' : isMessageSent ? 'Thank You' : 'Submit'}
            </Submit>
          </SubmitWrapper>
        </Form>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  background-color: #fff;
  padding: 9.3rem 0 3.125rem;

  @media screen and (min-width: 48rem) {
    padding: 12.4rem 0 12.95rem;
  }

  @media screen and (min-width: 75rem) {
    padding: 4rem 0 2rem;
  }

  @media screen and (min-width: 1536px) and (min-height: 900px) {
    padding: 0 0 4.4rem;
  }

  > div {
    grid-column: 2 / 3;
    margin: 0 auto;
    width: 100%;

    small {
    position: relative;
    margin-top: auto;
    bottom: 10px;
    font-size: 12px;
  }

    p {
      font-size: 1rem;
      font-weight: 700;
      margin-bottom: 1.25em;
    }

    @media screen and (min-width: 48rem) {
      max-width: 42rem;
    }

    @media screen and (min-width: 75rem) {
      display: flex;
      align-items: center;
      height: 100vh;
      justify-content: space-between;
      max-width: 1180px;

      form {
        max-width: 36.25rem;
        width: 100%;
      }
    }
  }
`;

const HiddenCheckbox = styled.input`
  cursor: pointer;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;
`;

const CustomCheckbox = styled.span`
  border: 1px solid var(--ax-grey);
  border-radius: 2px;
  cursor: pointer;
  flex-shrink: 0;
  height: 25px;
  width: 25px;
  position: relative;
  bottom: 1px;
  margin-left: 20px;

  &:after {
    content: '';
    display: none;
    position: relative;
    border-style: solid;
    border-color: var(--ax-gold);
    border-width: 0 3px 3px 0;
    height: 12.5px;
    left: 9px;
    top: 4px;
    transform: rotate(45deg);
    width: 7px;
  }
`;

const ContactDetails = styled.section`
  max-width: 600px;
  width: 100%;
  height: 623.547px;
  position: relative;
  margin-top: 4.5rem;
  h1 {
    max-width: 5.729em;
    position: relative;
    right: 4px;
    font-weight: 900;
    font-size: 3rem;
    bottom: 7px;
    margin-bottom: 4rem;
  }

  p {
    display: flex;
    max-width: 449px;
    width: 100%;

    svg {
      width: 48px;
      height: 48px;
    }

    span,
    a {
      font-size: 1.5rem;
      color: #000;
      font-weight: 400;
      max-width: 349px;
      line-height: 1.5em;
      position: relative;
      left: 52px;
      top: 7px;
      text-decoration: none;
    }

    &:last-of-type {
      margin-bottom: 69px;

      @media screen and (min-width: 1536px) {
        margin-bottom: 89px;
      }
    }
  }
`;

const TextAndChoiceButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 2.25rem;

  @media screen and (min-width: 450px) {
    align-items: center;
    flex-direction: row;

    > p {
      margin-bottom: 0 !important;
    }
  }
`;

const ChoiceButtons = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 352px;
  width: 100%;

  @media screen and (min-width: 450px) {
    margin-left: 1em;
  }
`;

const ChoiceButton = styled.div`
  align-items: center;
  border: 1px solid var(--ax-gold);
  border-radius: 2px;
  color: ${({ selected }) => (selected ? '#fff' : '#000')};
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  max-width: 166px;
  width: 100%;
  flex-shrink: 1;
  font-weight: 700;

  &:nth-of-type(1) {
    margin-right: 10px;
  }

  &:nth-of-type(2) {
    margin-left: 10px;
  }

  ${({ selected }) =>
    selected
      ? css`
          background-color: var(--ax-gold);
          transition: background-color 0.1s ease-in-out;
        `
      : css`
          background-color: transparent;
          transition: background-color 0.1s ease-in-out;
        `}
`;

const Label = styled.label`
  height: 1px;
  opacity: 0;
  width: 1px;
`;

const Input = styled.input`
  border-style: none;
  border-bottom-color: var(--ax-grey);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  color: #000;
  font-family: 'proxima-nova';
  height: 1.5rem;
  font-size: 0.875rem;
  font-weight: 300;
  margin: 0 auto 3.1em;
  outline: none;
  width: 100%;

  &::placeholder {
    color: var(--ax-grey);
    font-size: 0.875rem;
    font-weight: 300;
    font-style: italic;
  }
`;

const TextArea = styled(Input)`
  border: 1px solid var(--ax-grey);
  height: 228px;
  resize: none;
  padding: 0.75em 0.5em;
  margin-bottom: 1.714em;

  @media screen and (min-width: 75rem) {
    height: 80px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const AddToMailingList = styled.label`
  align-items: center;
  display: flex;
  margin-bottom: 1.714em;
  user-select: none;
  width: 14.375rem;

  span {
    font-weight: 700;
  }

  @media screen and (min-width: 25.5rem) {
    width: 100%;
  }

  &:hover input + span {
    background-color: #eee;
    transition: background-color 0.15s ease-in-out;
  }

  &:not(:hover) input + span {
    background-color: #fff;
    transition: background-color 0.15s ease-in-out;
  }

  & input:checked + span {
    background-color: #fff;
  }

  & input:checked + span:after {
    display: block;
  }
`;

const SubmitWrapper = styled.div`
  align-items: center;
  align-self: flex-end;
  display: flex;
  justify-content: space-between;

  span {
    margin-right: 1.5em;
    font-style: italic;
    transition: opacity 200ms ease-in-out;
    text-align: center;

    ${({ isMessageSent }) =>
      isMessageSent
        ? css`
            opacity: 1;
          `
        : css`
            opacity: 0;
          `};
  }
`;

const Submit = styled.button`
  ${LinkStyle}
  cursor: pointer;
  outline: none;

  ${({ isMessageSending }) =>
    isMessageSending &&
    css`
      &:after {
        content: ' .';
        margin-left: -3px;
        animation: dots 1s steps(5, end) infinite;
      }

      @keyframes dots {
        0%,
        20% {
          color: rgba(0, 0, 0, 0);
          text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
        }
        40% {
          color: #fff;
          text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
        }
        60% {
          text-shadow: 0.25em 0 0 #fff, 0.5em 0 0 rgba(0, 0, 0, 0);
        }
        80%,
        100% {
          text-shadow: 0.25em 0 0 #fff, 0.5em 0 0 #fff;
        }
      }
    `}
`;

export default Contact;
